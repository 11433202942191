import React, { useState, useEffect, useCallback } from 'react';
import {
  Container, Typography, Box, Alert, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, List, ListItem, ListItemText, Button, ButtonGroup, IconButton, Tooltip,
  MenuItem, Select, FormControl, InputLabel, CircularProgress, Card, Badge, Tabs, Tab, LinearProgress,
  TextField, Autocomplete
} from '@mui/material';
import { useParams, Link } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ArrowUpward, ArrowDownward, ArrowBack, ArrowForward, PushPin, Description, Grade, Input } from '@mui/icons-material';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { doc, getDoc, updateDoc, setDoc, collection, getDocs, deleteDoc, arrayUnion, arrayRemove, query, where } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthContext.js';
import { db } from '../../firebase';
import AddReviewForm from './AddReviewForm';
import AddReplyForm from './AddReplyForm';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { styled } from '@mui/material/styles';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend as RechartsLegend, ResponsiveContainer } from 'recharts';
import InteractiveGradeScale from './InteractiveGradeScale';
import GradeChart from './CustomGradeChart';
import VerifiedIcon from '@mui/icons-material/Verified';
import CanvasGradeTable from './CanvasGradeTable';
import CourseInputDataForm from './CourseInputDataForm';
import { PushPinOutlined } from '@mui/icons-material';
import { Sparkles } from 'lucide-react';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import ScienceIcon from '@mui/icons-material/Science';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import CourseAnalytics from './CourseAnalytics.jsx';
import CourseVoting from './CourseVoting.jsx';


const CourseReviewsPage = ({ darkMode }) => {
  const [isTaughtCurrentTerm, setIsTaughtCurrentTerm] = useState(false);
  const { department, courseId } = useParams();
  const { currentUser } = useAuth();
  const [reviews, setReviews] = useState([]);
  const [course, setCourse] = useState(null);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedProfessor, setSelectedProfessor] = useState('');
  const [loading, setLoading] = useState(true);
  const [vote, setVote] = useState(null);
  const [courseDescription, setCourseDescription] = useState('');
  const [pinned, setPinned] = useState(false);
  const [quality, setQuality] = useState(0); // Add this line
  const [showAllProfessors, setShowAllProfessors] = useState(false);
  const [currentInstructors, setCurrentInstructors] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [reviewInstructors, setReviewInstructors] = useState([]);
  const [allProfessors, setAllProfessors] = useState([]);
  const [professorInput, setProfessorInput] = useState('');
  const gradeToNum = {
    'A': 11, 'A-': 10, 'A/A-': 10.5,
    'B+': 9, 'A-/B+': 9.5, 'B': 8, 'B+/B': 8.5, 'B-': 7, 'B/B-': 7.5,
    'C+': 6, 'B-/C+': 6.5, 'C': 5, 'C/C+': 5.5, 'C-': 4, 'C/C-': 4.5,
    'D+': 3, 'C-/D+': 3.5, 'D': 2, 'D+/D': 2.5, 'D-': 1, 'D/D-': 1.5,
    'F': 0
  };
  const numToGrade = Object.fromEntries(Object.entries(gradeToNum).map(([k, v]) => [v, k]));


  // Define color variables based on darkMode
  const mainBgColor = darkMode 
    ? 'linear-gradient(90deg, #1C093F 0%, #0C0F33 100%)' 
    : '#F9F9F9';
  const paperBgColor = darkMode ? '#1C1F43' : '#FFFFFF';
  const tableHeaderBgColor = darkMode ? '#333333' : '#f0f0f0';
  const tableRowEvenBgColor = darkMode ? '#1C1F43' : '#F8F8F8';
  const tableRowOddBgColor = darkMode ? '#24273c' : '#FFFFFF';
  const textColor = darkMode ? '#FFFFFF' : '#333333';
  const headerTextColor = darkMode ? '#FFFFFF' : '#571CE0';
  const searchBgColor = darkMode ? '#0C0F33' : '#FFFFFF';
  const tooltipBgColor = darkMode ? '#333333' : '#f5f5f9';
  const tooltipTextColor = darkMode ? '#FFFFFF' : '#000000';


  const [deptAndNumber, ...rest] = courseId.split('__');
  const deptCode = deptAndNumber.match(/[A-Z]+/)[0];
  var courseNumber = deptAndNumber.match(/\d+/)[0];
  const numberRegex = /[A-Z]+_[A-Z]+(\d+(?:_\d+)?)/;
  const match = courseId.match(numberRegex);
  const [descriptionError, setDescriptionError] = useState(null);
  const reviewsPerPage = 5;
  const [isBetaUser, setIsBetaUser] = useState(false);

  useEffect(() => {
    const checkBetaStatus = async () => {
      if (currentUser) {
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setIsBetaUser(userData.beta === true);
        }
      }
    };

    checkBetaStatus();
  }, [currentUser]);

  const StyledTab = styled(Tab)(({ theme }) => ({
    minHeight: 'auto',
    padding: '8px 16px',
    color: theme.palette.text.primary,
    '&.Mui-selected': {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
  }));

  const [gradeData, setGradeData] = useState([]);
  const [newGradeData, setNewGradeData] = useState({
    Term: '',
    Professors: [],
    Grade: ''
  });

  const handleGradeChange = (newGrade) => {
    setNewGradeData(prevData => ({
      ...prevData,
      Grade: newGrade
    }));
  };


  const handleFinalGradeSelect = (finalGrade) => {
    console.log('Final grade selected:', finalGrade);
    setNewGradeData(prevData => ({
      ...prevData,
      Grade: finalGrade
    }));
  };
  

  const normalizeName = (name) => {
    return name.toLowerCase().replace(/\s+/g, ' ').trim();
  };

  const compareNames = (name1, name2) => {
    if (!name1 || !name2) return false;
    
    const norm1 = normalizeName(name1);
    const norm2 = normalizeName(name2);
  
    // Check for exact match
    if (norm1 === norm2) return true;
  
    // Check if one name is a subset of the other
    if (norm1.includes(norm2) || norm2.includes(norm1)) return true;
  
    // Split names into parts and check for partial matches
    const parts1 = norm1.split(' ');
    const parts2 = norm2.split(' ');
  
    // Check if all parts of the shorter name are in the longer name
    const [shorter, longer] = parts1.length < parts2.length ? [parts1, parts2] : [parts2, parts1];
    return shorter.every(part => longer.some(longPart => longPart.includes(part)));
  };

  const handleProfessorChange = (event, newValue) => {
    setNewGradeData(prevData => ({
      ...prevData,
      Professors: newValue
    }));
  };
  
  const handleProfessorFilterChange = (event) => {
    setSelectedProfessor(event.target.value);
    setCurrentPage(1); // Reset to the first page
  };
  
  

  const CourseMetricsIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 3v18h18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18 9l-5 5-2-2-4 4" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );

  // Custom styled Tabs component
  const StyledTabs = styled(Tabs)(({ theme }) => ({
    minHeight: 'auto',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  }));

  const CourseDescriptionSection = () => {
    const hasValidMetrics = course?.metrics && (
      course.metrics.difficulty_score > 0 ||
      course.metrics.quality_score > 0 ||
      course.metrics.sentiment_score > 0 ||
      course.metrics.workload_score > 0
    );
  
    return (
      <Box sx={{ padding: '20px' }}>
        {/* 1. Update Typography color for header */}
        <Typography 
          variant="h6" 
          gutterBottom 
          sx={{ 
            fontWeight: 600, 
            color: headerTextColor, // Changed from '#1D1D1F' to dynamic variable
          }}
        >
          College Description
        </Typography>
  
        {/* 2. Update Typography color for description */}
        <Typography
          variant="body1"
          sx={{ 
            fontSize: '0.95rem', 
            color: textColor,         // Changed from 'text.primary' to dynamic variable
            textAlign: 'left', 
            lineHeight: '1.6',
            marginBottom: '2rem'
          }}
          dangerouslySetInnerHTML={{ __html: courseDescription }}
        />
  
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 2, 
          marginBottom: '1rem'
        }}>
          {/* 3. Update Typography color for AI Summary header */}
          <Typography 
            variant="h6" 
            sx={{ 
              fontWeight: 600, 
              color: headerTextColor, // Changed from '#1D1D1F' to dynamic variable
            }}
          >
            AI Summary of Reviews
          </Typography>
  
          {/* 4. Update Tooltip Background and Text Colors */}
          <Box className="flex-shrink-0 relative group">
            <Sparkles className="w-5 h-5 text-indigo-600 cursor-help" />
            <Box
              sx={{
                position: 'absolute',
                bottom: '100%',
                left: '50%',
                transform: 'translateX(-50%)',
                mb: 2,
                display: 'none',
                width: '200px',
                p: 2,
                bgcolor: tooltipBgColor,          // Changed from 'bg-white' to dynamic variable
                color: tooltipTextColor,          // Changed from 'text-gray-600' to dynamic variable
                borderRadius: '8px',
                boxShadow: 3,
                textAlign: 'center',
                fontSize: '0.875rem',
                border: darkMode ? '1px solid #444444' : '1px solid #e9ecef', // Optional: Add border based on darkMode
                '& .MuiBox-root': {
                  position: 'absolute',
                  top: '100%',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  width: '0',
                  height: '0',
                  borderLeft: '6px solid transparent',
                  borderRight: '6px solid transparent',
                  borderTop: `6px solid ${tooltipBgColor}`, // Triangle arrow matching tooltip background
                },
                '.group:hover &': {
                  display: 'block',
                },
              }}
            >
              AI-generated summary based on student reviews
              <Box />
            </Box>
          </Box>
        </Box>
  
        {/* 5. Update Summary Typography and Box */}
        {course?.summary ? (
          <Typography
            variant="body1"
            sx={{
              fontSize: '0.95rem',
              color: textColor,                   // Changed from 'text.primary' to dynamic variable
              textAlign: 'left',
              lineHeight: '1.6',
              padding: '1rem',
              backgroundColor: paperBgColor,      // Changed from '#f8f9fa' to dynamic variable
              borderRadius: '8px',
              border: darkMode ? '1px solid #444444' : '1px solid #e9ecef', // Changed from '#e9ecef' to dynamic border
            }}
          >
            {course.summary}
          </Typography>
        ) : (
          <Box
            sx={{
              padding: '1rem',
              backgroundColor: paperBgColor,      // Changed from '#f8f9fa' to dynamic variable
              borderRadius: '8px',
              border: darkMode ? '1px solid #444444' : '1px solid #e9ecef', // Changed from '#e9ecef' to dynamic border
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: '0.95rem',
                color: darkMode ? '#FFFFFF' : '#8E8E93', // Changed from 'text.secondary' to dynamic color
                fontStyle: 'italic'
              }}
            >
              Not enough data to generate an AI summary. This will be available once more students review the course.
            </Typography>
          </Box>
        )}
      </Box>
    );
  };
  

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const renderTabContent = () => {
    switch (tabValue) {
      case 0:
        // Description tab content
        return <CourseDescriptionSection darkMode={darkMode}  />;
      case 1:
        // Grades Distribution tab content
        return (
          <Box sx={{ padding: '20px' }}>
            <Typography variant="h6" gutterBottom>
              Grades Distribution
            </Typography>
            <Typography
              variant="caption"
              sx={{
                fontSize: '0.7rem',
                fontStyle: 'italic',
                color: 'text.secondary',
                mt: 1,
                display: 'block',
              }}
            >
              *Note: Sections with different medians may be averaged for the term.
            </Typography>
            {gradeData.length > 0 ? (
              <>
                {renderGradeChart()}
                <CanvasGradeTable
                  gradeData={gradeData.sort((a, b) => {
                    darkMode={darkMode} 
                    const aYear = parseInt(a.Term.slice(0, 2));
                    const bYear = parseInt(b.Term.slice(0, 2));
                    if (aYear !== bYear) return bYear - aYear;
                    const termOrder = { F: 0, X: 1, S: 2, W: 3 };
                    return termOrder[a.Term.slice(2)] - termOrder[b.Term.slice(2)];
                  })}
                />
              </>
            ) : (
              <Typography>
                No grade distribution information available. Add medians from previous classes to improve our offerings.
              </Typography>
            )}
            <Box sx={{ marginTop: 4 }}>
              <Typography variant="h6" gutterBottom>
                Add New Grade Data
              </Typography>
              <TextField
                name="Term"
                label="Term"
                value={newGradeData.Term}
                onChange={(e) => {
                  const value = e.target.value.toUpperCase(); // Convert input to uppercase
  
                  // Allow only two digits (00-24) followed by F, W, S, or X
                  const regex = /^(?:2[0-4]|1\d|0?\d)([FWSX])?$/;
  
                  if (regex.test(value) || value === '') {
                    setNewGradeData((prev) => ({ ...prev, Term: value }));
                  }
                }}
                fullWidth
                margin="normal"
              />
              <Autocomplete
                multiple
                id="professors-input"
                options={allProfessors}
                value={newGradeData.Professors}
                onChange={(event, newValue) => setNewGradeData((prev) => ({ ...prev, Professors: newValue }))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Professors"
                    placeholder="Select or enter professors"
                    fullWidth
                    margin="normal"
                  />
                )}
                freeSolo
                sx={{ marginTop: 2, marginBottom: 2 }}
              />
              <InteractiveGradeScale value={newGradeData.Grade} onChange={handleGradeChange} darkMode={darkMode} />
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddNewGradeData}
                sx={{ marginTop: 2 }}
              >
                Add Grade Data
              </Button>
            </Box>
          </Box>
        );
      case 3:
        // Input Data tab content
        return (
          <Box sx={{ padding: '20px'  }}>
            <Typography variant="h6" gutterBottom>
              Input Course Data
            </Typography>
            <CourseInputDataForm courseId={courseId} allProfessors={allProfessors} />
          </Box>
        );
        case 2:
  return (
    <Box sx={{ padding: '20px' }}>
      <Typography variant="h6" gutterBottom>
        Course Metrics
      </Typography>
      
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
      <CourseVoting 
          course={course}
          courseId={courseId}
          currentUser={currentUser}
        />
        <CourseAnalytics metrics={course?.metrics}
        darkMode={darkMode} />
      </Box>
    </Box>
  );
      default:
        return null;
    }
  };
  

  useEffect(() => {
    const fetchAllProfessors = async () => {
      try {
        const courseDocRef = doc(db, 'courses', courseId);
        const courseDocSnap = await getDoc(courseDocRef);
        
        if (courseDocSnap.exists()) {
          const courseData = courseDocSnap.data();
          if (courseData.professors) {
            setAllProfessors(courseData.professors);
          }
        }
      } catch (error) {
        console.error('Error fetching all professors:', error);
      }
    };

    fetchAllProfessors();
  }, [courseId]);


  const fetchReviews = useCallback(async () => {
    setLoading(true);
    console.log('Fetching reviews...');
    const fetchDocument = async (path) => {
      const docRef = doc(db, path);
      const docSnap = await getDoc(docRef);
      return docSnap.exists() ? docSnap.data() : null;
    };
  
    try {
      let data = null;
      const transformedCourseIdMatch = courseId.match(/([A-Z]+\d{3}_\d{2})/);
      const transformedCourseId = transformedCourseIdMatch ? transformedCourseIdMatch[0] : null;
  
      if (transformedCourseId) {
        data = await fetchDocument(`reviews/${transformedCourseId}`);
      }
  
      if (!data) {
        const sanitizedCourseId = courseId.split('_')[1];
        data = await fetchDocument(`reviews/${sanitizedCourseId}`);
      }
  
      if (data) {
        const reviewsArray = [];
        const professorsSet = new Set();

        Object.entries(data).forEach(([instructor, reviewList]) => {
          professorsSet.add(instructor);

          if (Array.isArray(reviewList)) {
            reviewList.forEach((review, index) => {
              // Match terms using the updated regex pattern
              const termMatch = review.match(/^(0[1-9]|1[0-9]|2[0-4]|[1-9])[WSXF]/);
              const termCode = termMatch ? termMatch[0] : null;
          
              // Use try-catch to safely call getTermValue
              let termValue = 0; // Default value
              if (termCode) {
                try {
                  termValue = getTermValue(termCode); // Retrieve the term value
                } catch (error) {
                  console.error("Error retrieving term value:", error);
                }
              }
          
              reviewsArray.push({
                instructor,
                review,
                reviewIndex: index,
                courseId,
                termValue,
              });
            });
          }
          
        });
  
        // Sort by termValue in descending order (latest first)
        reviewsArray.sort((a, b) => b.termValue - a.termValue);
  
        setReviews(reviewsArray);
        setAllProfessors(Array.from(professorsSet));
      } else {
        setError('No reviews found for this course.');
        setAllProfessors([]);
      }
    } catch (error) {
      console.error('Error fetching reviews:', error);
      setError('Failed to fetch reviews.');
      setAllProfessors([]);
    } finally {
      setLoading(false);
      console.log('Finished fetching reviews');
    }
  }, [courseId]);

  const fetchGradeData = useCallback(async () => {
    try {
      const courseDocRef = doc(db, 'courses', courseId);
      const courseDocSnap = await getDoc(courseDocRef);
      
      if (courseDocSnap.exists()) {
        const courseData = courseDocSnap.data();
        if (courseData.medians && Array.isArray(courseData.medians)) {
          const updatedMedians = courseData.medians.map(median => ({
            ...median,
            verified: median.verified !== undefined ? median.verified : true,
            submissions: median.submissions || []
          }));
          setGradeData(updatedMedians);

          // Update the document if any changes were made
          if (JSON.stringify(updatedMedians) !== JSON.stringify(courseData.medians)) {
            await updateDoc(courseDocRef, { medians: updatedMedians });
          }
        }
      }
    } catch (error) {
      console.error('Error fetching grade data:', error);
      setError('Failed to fetch grade data.');
    }
  }, [courseId]);

  useEffect(() => {
    let isMounted = true;
  
    const fetchData = async () => {
      setLoading(true);
      try {
        if (isMounted) {
          await Promise.all([fetchCourse(), fetchReviews(), fetchUserVote(), fetchCourseDescription(), fetchGradeData()]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        if (isMounted) {
          setError('Failed to fetch data. Please try refreshing the page.');
        }
      } finally {
        if (isMounted) {
          setLoading(false);
          console.log('Finished fetching all data');
        }
      }
    };
  
    fetchData();
  
    return () => {
      isMounted = false;
    };
  }, [courseId, department, fetchGradeData]);

  const handleNewGradeDataChange = (event) => {
    const { name, value } = event.target;
    setNewGradeData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleAddNewGradeData = async () => {
    if (!currentUser) {
      setError('You must be logged in to submit grade data.');
      return;
    }
  
    console.log('Current newGradeData:', newGradeData);
  
    if (!newGradeData.Term.trim()) {
      setError('Please enter a term.');
      return;
    }
  
    if (!newGradeData.Grade) {
      setError('Please select a grade.');
      return;
    }
  
    if (newGradeData.Professors.length === 0) {
      setError('Please enter at least one professor.');
      return;
    }
  
    try {
      const userDocRef = doc(db, 'users', currentUser.uid);
      const userDocSnap = await getDoc(userDocRef);
  
      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        const existingSubmission = userData.gradeSubmissions?.find(
          sub => sub.courseId === courseId
        );
  
        if (existingSubmission) {
          setError('You have already submitted a median for this course. You can only submit one median per course.');
          return;
        }
      }
  
      const courseDocRef = doc(db, 'courses', courseId);
      const courseDocSnap = await getDoc(courseDocRef);
      
      if (courseDocSnap.exists()) {
        const courseData = courseDocSnap.data();
        const existingMedian = courseData.medians.find(m => m.Term === newGradeData.Term);
  
        if (existingMedian && existingMedian.verified) {
          setError('This term already has verified grade data. Your submission cannot be accepted.');
          return;
        }
  
        const newSubmission = {
          Grade: newGradeData.Grade,
          timestamp: new Date().toISOString(),
          userId: currentUser.uid
        };
  
        if (existingMedian) {
          // Update existing unverified median
          const updatedMedians = courseData.medians.map(m => 
            m.Term === newGradeData.Term 
              ? { 
                  ...m, 
                  submissions: [...m.submissions, newSubmission],
                  Professors: [...new Set([...m.Professors, ...newGradeData.Professors])]
                }
              : m
          );
  
          await updateDoc(courseDocRef, { medians: updatedMedians });
        } else {
          // Add new unverified median
          await updateDoc(courseDocRef, {
            medians: arrayUnion({
              Term: newGradeData.Term,
              Professors: newGradeData.Professors,
              verified: false,
              submissions: [newSubmission]
            })
          });
        }
  
        // Add user submission
        await updateDoc(userDocRef, {
          gradeSubmissions: arrayUnion({
            courseId,
            Term: newGradeData.Term,
            Professors: newGradeData.Professors,
            Grade: newGradeData.Grade,
            timestamp: new Date().toISOString()
          })
        });
  
        await fetchGradeData();
        
        setNewGradeData({
          Term: '',
          Professors: [],
          Grade: ''
        });
  
        setError(null);
      }
    } catch (error) {
      console.error('Error adding new grade data:', error);
      setError('Failed to add new grade data.');
    }
  };

  const calculateMedianGrade = (submissions) => {
    if (!submissions || submissions.length === 0) return 'N/A';
    const validGrades = submissions.map(sub => gradeToNum[sub.Grade]).filter(grade => grade !== undefined);
    if (validGrades.length === 0) return 'N/A';
    
    validGrades.sort((a, b) => a - b);
    const mid = Math.floor(validGrades.length / 2);
    const median = validGrades.length % 2 !== 0 ? validGrades[mid] : (validGrades[mid - 1] + validGrades[mid]) / 2;
    
    return numToGrade[Math.round(median)] || 'N/A';
  };


  // const calculateAverageGrade = (submissions) => {
  //   if (!submissions || submissions.length === 0) return 'N/A';
  //   const validSubmissions = submissions.filter(sub => sub.Grade in gradeToNum);
  //   if (validSubmissions.length === 0) return 'N/A';
  //   const sum = validSubmissions.reduce((acc, sub) => acc + gradeToNum[sub.Grade], 0);
  //   const average = sum / validSubmissions.length;
  //   const closestGrade = Object.entries(gradeToNum).reduce((a, b) => 
  //     Math.abs(b[1] - average) < Math.abs(a[1] - average) ? b : a
  //   )[0];
  //   return closestGrade;
  // };
  const renderGradeChart = () => {
    const processedGradeData = gradeData.map(item => ({
      ...item,
      Grade: item.verified ? item.Grade : calculateMedianGrade(item.submissions),
      submissionCount: item.submissions ? item.submissions.length : 0
    }));
    return <GradeChart gradeData={processedGradeData} />;
  };
  // helper function to the sort the reviews
  const getTermValue = (termCode) => {
    const year = parseInt(termCode.slice(0, 2), 10);
    const term = termCode.slice(2);
    let termValue;
  
    switch (term) {
      case 'W': // Winter
        termValue = 1;
        break;
      case 'S': // Spring
        termValue = 2;
        break;
      case 'X': // Summer
        termValue = 3;
        break;
      case 'F': // Fall
        termValue = 4;
        break;
      default:
        termValue = 0; // Just in case
        break;
    }
  
    return year * 10 + termValue; // Multiplying the year to get a comparable numeric value
  };
  
  

  const fetchCourse = useCallback(async () => {
    setLoading(true);
    console.log('Fetching course...');
    try {
        const docRef = doc(db, 'courses', courseId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const courseData = docSnap.data();
            if (courseData.layup === undefined) {
                courseData.layup = 0;
            }
            if (courseData.quality === undefined) {
                courseData.quality = 0; // Set a default value if quality is not present
            }
            setCourse(courseData);
            setQuality(courseData.quality); // Update quality state
        } else {
            setError('Course not found.');
        }
    } catch (error) {
        console.error('Error fetching course:', error);
        setError('Failed to fetch course.');
    } finally {
        setLoading(false);
        console.log('Finished fetching course');
    }
}, [courseId]);
const handleQualityVote = async (voteType) => {
  if (!course || !currentUser) return;

  const userDocRef = doc(db, 'users', currentUser.uid);
  const courseRef = doc(db, 'courses', courseId);

  let newQuality = quality !== undefined ? quality : 0;

  if (vote === voteType) {
      newQuality = voteType === 'upvote' ? newQuality - 1 : newQuality + 1;
      await updateDoc(courseRef, { quality: newQuality });
      await setDoc(userDocRef, { votes: { [`quality_${courseId}`]: null } }, { merge: true });
      setVote(null);
  } else {
      if (vote === 'upvote') {
          newQuality -= 1;
      } else if (vote === 'downvote') {
          newQuality += 1;
      }
      newQuality = voteType === 'upvote' ? newQuality + 1 : newQuality - 1;

      await updateDoc(courseRef, { quality: newQuality });
      await setDoc(userDocRef, { votes: { [`quality_${courseId}`]: voteType } }, { merge: true });
      setVote(voteType);
  }

  setQuality(newQuality);
};



  const fetchUserVote = useCallback(async () => {
    if (!currentUser) return;
    const userDocRef = doc(db, 'users', currentUser.uid);
    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists()) {
      const userData = userDocSnap.data();
      const userVote = userData.votes ? userData.votes[courseId] : null;
      setVote(userVote);
      setPinned(userData.pinnedCourses ? userData.pinnedCourses.includes(courseId) : false);
    }
  }, [currentUser, courseId]);

  const fetchCourseDescription = async () => {
    try {
      console.log("Fetching course description for courseId:", courseId);
  
      const courseDocRef = doc(db, 'courses', courseId);
      const courseDocSnap = await getDoc(courseDocRef);
      
      if (courseDocSnap.exists()) {
        const courseData = courseDocSnap.data();

        const courseIdParts = courseId.split('__');
        const deptCodeMatch = courseIdParts[0].match(/[A-Z]+/);
        const courseNumberMatch = courseIdParts[0].match(/\d+/);
        let instructors = [];
        if (deptCodeMatch && courseNumberMatch) {
          const deptCode = deptCodeMatch[0];
          const courseNumber = courseNumberMatch[0].replace(/^0+/, '');
          console.log("fetching current instructors")
          try {
            const fallTimetableRef = collection(db, 'winterTimetable');
            console.log("deptCode:", deptCode, "courseNumber:", courseNumber);
            const q = query(fallTimetableRef, where("Subj", "==", deptCode), where("Num", "==", courseNumber));
            const querySnapshot = await getDocs(q);
            
            let found = false;
            querySnapshot.forEach((doc) => {
              const data = doc.data();
              if (data.Instructor) {
                found = true;
                if (!instructors.includes(data.Instructor)) {
                  instructors.push(data.Instructor);
                }
              }
            });
            console.log("Matching instructors:", instructors);

            setIsTaughtCurrentTerm(found);
            if (instructors.length > 0) {
              setCurrentInstructors(instructors);
            } else {
              console.log("No instructors found for this course");
            }
          } catch (error) {
            console.error("Error fetching documents:", error);
          }
        }
        // If the description already exists in the document, use it
        if (courseData.description) {
          setCourseDescription(courseData.description);
          setDescriptionError(null);
          console.log("Course description found in Firestore:", courseData.description);
        } else {
          // If the description doesn't exist, fetch it from the Dartmouth website
  
          // Extract department code and course number from courseId
  
          if (deptCodeMatch && courseNumberMatch) {
            const deptCode = deptCodeMatch[0];
            const courseNumber = courseNumberMatch[0];
            if (deptCode && courseNumber) {
              console.log("Department:", deptCode, "Course Number:", courseNumber);
            }
            else {
              console.log("errorsdfasdf;c")
            }
            const response = await fetch(`${API_URL}/fetch-text?subj=${deptCode}&numb=${courseNumber}`);
            console.log("deptCode:", deptCode, "courseNumber:", courseNumber);
            
            if (!response.ok) {
              const errorData = await response.json();
              throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
            }
            
            const data = await response.json();
            
            if (data.content) {
              setCourseDescription(data.content);
              setDescriptionError(null);
              console.log("Fetched course description from Dartmouth website:", data.content);
  
              // Save the fetched description to the 'courses' collection
              await updateDoc(courseDocRef, { description: data.content });
              console.log("Saved course description to Firestore in the 'courses' collection");
            } else {
              throw new Error('No content in the response');
            }
          } else {
            throw new Error('Course number or department code not found');
          }
        }
      } else {
        throw new Error('Course not found in Firestore');
      }
    }
    catch (error) {
      console.error('Error fetching course description:', error);
      setDescriptionError('Course description not available');
      setCourseDescription('Course description not available or class has not been recently offered');
    }
  };
  
  const fetchInstructors = useCallback(async () => {
    try {
      const courseIdParts = courseId.split('__');
      const deptCodeMatch = courseIdParts[0].match(/[A-Z]+/);
      const courseNumberMatch = courseIdParts[0].match(/\d+/);

      if (deptCodeMatch && courseNumberMatch) {
        const deptCode = deptCodeMatch[0];
        const courseNumber = courseNumberMatch[0].replace(/^0+/, '');
        console.log("Fetching current instructors");

        const fallTimetableRef = collection(db, 'winterTimetable');
        const q = query(fallTimetableRef, where("Subj", "==", deptCode), where("Num", "==", courseNumber));
        const querySnapshot = await getDocs(q);
        
        let instructors = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.Instructor && !instructors.includes(data.Instructor)) {
            instructors.push(data.Instructor);
          }
        });

        setCurrentInstructors(instructors);
        console.log("Current instructors:", instructors);
        let data = null;
        const transformedCourseIdMatch = courseId.match(/([A-Z]+\d{3}_\d{2})/);
        const transformedCourseId = transformedCourseIdMatch ? transformedCourseIdMatch[0] : null;
        var reviewsRef = null
        if (transformedCourseId) {
          reviewsRef = doc(db, 'reviews', `${transformedCourseId}`);
        }
    
        if (!data) {
          const sanitizedCourseId = courseId.split('_')[1];
          reviewsRef = doc(db, 'reviews', `${sanitizedCourseId}`);
        }
        if (!reviewsRef) {
          return;
        }
        // Fetch review instructors
        const reviewsDoc = await getDoc(reviewsRef);
        
        if (reviewsDoc.exists()) {
          const reviewsData = reviewsDoc.data();
          const reviewInstructors = Object.keys(reviewsData);
          setReviewInstructors(reviewInstructors);
          console.log("Review instructors:", reviewInstructors);

          // Compare and update if necessary
          const instructorsToAdd = instructors.filter(instructor => 
            !reviewInstructors.some(reviewInstructor => compareNames(instructor, reviewInstructor))
          );

          if (instructorsToAdd.length > 0) {
            const updatedReviews = { ...reviewsData };
            instructorsToAdd.forEach(instructor => {
              updatedReviews[instructor] = [];
            });

            await setDoc(reviewsRef, updatedReviews);
            console.log("Added new instructors to reviews:", instructorsToAdd);
          }
        } else {
          // If the document doesn't exist, create it with the current instructors
          const newReviewsData = {};
          instructors.forEach(instructor => {
            newReviewsData[instructor] = [];
          });
          await setDoc(reviewsRef, newReviewsData);
          console.log("Created new reviews document with instructors:", instructors);
        }

        setIsTaughtCurrentTerm(instructors.length > 0);
      }
    } catch (error) {
      console.error("Error fetching and updating instructors:", error);
    }
  }, [courseId]);
  

  const API_URL = process.env.REACT_APP_API_URL || 'https://url-text-fetcher-368299696124.us-central1.run.app';
  
  useEffect(() => {
    let isMounted = true;
  
    const fetchData = async () => {
      setLoading(true);
      try {
        if (isMounted) {
          await Promise.all([fetchCourse(), fetchReviews(), fetchUserVote(), fetchCourseDescription(), fetchGradeData(), fetchInstructors()]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        if (isMounted) {
          setError('Failed to fetch data. Please try refreshing the page.');
        }
      } finally {
        if (isMounted) {
          setLoading(false);
          console.log('Finished fetching all data');
        }
      }
    };
  
    fetchData();
  
    return () => {
      isMounted = false;
    };
  }, [courseId, department, fetchGradeData, fetchInstructors]);

  const handleVote = async (voteType) => {
    if (!course || !currentUser) return;

    const userDocRef = doc(db, 'users', currentUser.uid);
    const courseRef = doc(db, 'courses', courseId);

    let newLayup = course.layup !== undefined ? course.layup : 0;

    if (vote === voteType) {
      newLayup = voteType === 'upvote' ? newLayup - 1 : newLayup + 1;
      await updateDoc(courseRef, { layup: newLayup });
      await setDoc(userDocRef, { votes: { [courseId]: null } }, { merge: true });
      setVote(null);
    } else {
      if (vote === 'upvote') {
        newLayup -= 1;
      } else if (vote === 'downvote') {
        newLayup += 1;
      }
      newLayup = voteType === 'upvote' ? newLayup + 1 : newLayup - 1;

      await updateDoc(courseRef, { layup: newLayup });
      await setDoc(userDocRef, { votes: { [courseId]: voteType } }, { merge: true });
      setVote(voteType);
    }

    setCourse((prev) => ({ ...prev, layup: newLayup }));
  };

  const handlePinCourse = async () => {
    if (!currentUser) return;

    const userDocRef = doc(db, 'users', currentUser.uid);

    try {
      if (pinned) {
        await updateDoc(userDocRef, {
          pinnedCourses: arrayRemove(courseId),
        });
        setPinned(false);
      } else {
        await updateDoc(userDocRef, {
          pinnedCourses: arrayUnion(courseId),
        });
        setPinned(true);
      }
    } catch (error) {
      console.error('Error pinning/unpinning course:', error);
    }
  };

  const splitReviewText = (review) => {
    if (typeof review !== 'string' || !review) {
      console.warn('Invalid review format:', review);
      return { prefix: '', rest: '' };
    }
    const match = review.match(/(.*?\d{2}[A-Z] with [^:]+: )([\s\S]*)/);
    if (match) {
      const [prefix, rest] = match.slice(1, 3);
      return { prefix, rest };
    } else {
      return { prefix: '', rest: review };
    }
  };

  const handleChangePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const addReplyLocally = (reviewIndex, newReply) => {
    setReviews((prevReviews) =>
      prevReviews.map((review) =>
        review.reviewIndex === reviewIndex
          ? { ...review, replies: Array.isArray(review.replies) ? [...review.replies, newReply] : [newReply] }
          : review
      )
    );
  };

  const ReviewItem = ({ instructor, prefix, rest, courseId, reviewIndex, onReplyAdded }) => {
    const { ref, inView } = useInView({ threshold: 0.1 });
    const { currentUser } = useAuth();
    const [replies, setReplies] = useState([]);
    const [showReplies, setShowReplies] = useState(false);
    const [replyCount, setReplyCount] = useState(0);
    const [likeCount, setLikeCount] = useState(0);
    const [hasLiked, setHasLiked] = useState(false);

    const fetchReplies = async () => {
      try {
        const transformedCourseIdMatch = courseId.match(/([A-Z]+\d{3}_\d{2})/);
        const transformedCourseId = transformedCourseIdMatch ? transformedCourseIdMatch[0] : null;
        const sanitizedCourseId = transformedCourseId ? transformedCourseId : courseId.split('_')[1];
        const sanitizedInstructor = instructor.replace(/\./g, '_');

        const repliesCollectionRef = collection(
          db,
          'reviews',
          sanitizedCourseId,
          `${sanitizedInstructor}_${reviewIndex}_replies`
        );
        const replyDocs = await getDocs(repliesCollectionRef);

        const fetchedReplies = replyDocs.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setReplies(fetchedReplies);
        setReplyCount(fetchedReplies.length);
      } catch (error) {
        console.error('Error fetching replies:', error);
        setError('Failed to fetch replies.');
      }
    };

    const fetchLikes = async () => {
      try {
        const transformedCourseIdMatch = courseId.match(/([A-Z]+\d{3}_\d{2})/);
        const transformedCourseId = transformedCourseIdMatch ? transformedCourseIdMatch[0] : null;
        const sanitizedCourseId = transformedCourseId ? transformedCourseId : courseId.split('_')[1];
        const sanitizedInstructor = instructor.replace(/\./g, '_');

        const likesCollectionRef = collection(
          db,
          'reviews',
          sanitizedCourseId,
          `${sanitizedInstructor}_${reviewIndex}_likes`
        );
        const likeDocs = await getDocs(likesCollectionRef);

        const fetchedLikes = likeDocs.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setLikeCount(fetchedLikes.length);

        if (currentUser) {
          const userLike = fetchedLikes.find((like) => like.userId === currentUser.uid);
          setHasLiked(!!userLike);
        }
      } catch (error) {
        console.error('Error fetching likes:', error);
        setError('Failed to fetch likes.');
      }
    };

    useEffect(() => {
      fetchReplies();
      fetchLikes();
    }, [courseId, instructor, reviewIndex]);

    const toggleReplies = () => {
      setShowReplies(!showReplies);
    };

    const handleLike = async () => {
      const transformedCourseIdMatch = courseId.match(/([A-Z]+\d{3}_\d{2})/);
      const transformedCourseId = transformedCourseIdMatch ? transformedCourseIdMatch[0] : null;
      const sanitizedCourseId = transformedCourseId ? transformedCourseId : courseId.split('_')[1];
      const sanitizedInstructor = instructor.replace(/\./g, '_');

      const likesCollectionRef = collection(
        db,
        'reviews',
        sanitizedCourseId,
        `${sanitizedInstructor}_${reviewIndex}_likes`
      );
      const likeDocRef = doc(likesCollectionRef, currentUser.uid);

      if (hasLiked) {
        await deleteDoc(likeDocRef);
        setLikeCount(likeCount - 1);
        setHasLiked(false);
      } else {
        await setDoc(likeDocRef, { userId: currentUser.uid });
        setLikeCount(likeCount + 1);
        setHasLiked(true);
      }
    };



    return (
      
      <motion.div
        ref={ref}
        initial={{ opacity: 0, y: 20 }}
        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
        transition={{ duration: 0.6, ease: 'easeOut' }}
        style={{ width: '100%' }}
      >
        <Box
        sx={{
          my: 3,
          background: darkMode
            ? 'linear-gradient(to right, rgba(30, 30, 30, 0.8), rgba(50, 50, 50, 0.8))' // Dark mode background
            : 'linear-gradient(to right, rgba(238, 242, 255, 0.8), rgba(245, 243, 255, 0.8))', // Light mode background
          borderRadius: '12px',
          overflow: 'hidden',
          border: darkMode
            ? '1px solid rgba(255, 255, 255, 0.1)'
            : '1px solid rgba(99, 102, 241, 0.1)',
          boxShadow: darkMode
            ? '0 4px 6px -1px rgba(255, 255, 255, 0.05), 0 2px 4px -1px rgba(255, 255, 255, 0.03)'
            : '0 4px 6px -1px rgba(0, 0, 0, 0.05), 0 2px 4px -1px rgba(0, 0, 0, 0.03)',
          transition: 'all 0.3s ease',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: darkMode
              ? '0 6px 12px -2px rgba(255, 255, 255, 0.08), 0 3px 6px -2px rgba(255, 255, 255, 0.05)'
              : '0 6px 12px -2px rgba(0, 0, 0, 0.08), 0 3px 6px -2px rgba(0, 0, 0, 0.05)',
          },
        }}
      >
          <ListItem sx={{ p: 3, alignItems: 'flex-start' }}>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1 }}>
                <Box
                  sx={{
                    width: '4px',
                    height: '24px',
                    bgcolor: darkMode ? '#4CAF50' : '#00693E', // Example: Lighter green in dark mode
                    borderRadius: '4px',
                  }}
                />
                <Typography
                  component="span"
                  sx={{
                    color: textColor, // Use dynamic text color
                    fontWeight: 600,
                    letterSpacing: '0.3px',
                    fontSize: '1rem',
                  }}
                >
                  {prefix}
                </Typography>
              </Box>
              <Typography
                component="p"
                sx={{
                  color: textColor, // Use dynamic text color
                  pl: '28px',
                  lineHeight: 1.6,
                  fontSize: '0.95rem',
                }}
              >
                {rest}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mt: 2,
                  pl: '28px',
                  gap: 2,
                }}
              >
                <IconButton
                  onClick={handleLike}
                  sx={{
                    color: hasLiked ? '#007AFF' : '#8E8E93',
                    padding: '6px',
                  }}
                >
                  <Typography 
                      variant="body2" 
                      sx={{ 
                        fontSize: '1.2rem',
                        color: hasLiked ? '#007AFF' : (darkMode ? '#FFFFFF' : '#8E8E93'), // Dynamic color based on state and theme
                      }}
                    >
                      🔥
                    </Typography>

                </IconButton>
                <Typography variant="body2" sx={{ color: '#8E8E93' }}>
                  {likeCount}
                </Typography>
                <IconButton
                  onClick={toggleReplies}
                  sx={{ color: '#007AFF', padding: '6px' }}
                >
                  <ChatBubbleOutlineIcon />
                </IconButton>
                <Typography variant="body2" sx={{ color: '#8E8E93' }}>
                  {replyCount}
                </Typography>
              </Box>
            </Box>
          </ListItem>
          {showReplies && (
            <>
              <List sx={{ pl: 4 }}>
                {replies.map((reply, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      backgroundColor: darkMode ? '#2a2a2a' : '#F9F9F9', // Darker background in dark mode
                      borderRadius: '8px',
                      marginTop: '8px',
                      alignItems: 'flex-start',
                    }}
                  >
                    <ListItemText
                      primary={
                        <>
                                                  <Typography
                          component="span"
                          sx={{ color: textColor, fontWeight: 600, fontSize: '0.9rem' }} // Dynamic text color
                        >
                          Reply:
                        </Typography>{' '}
                        <Typography
                          component="span"
                          sx={{ color: textColor, fontSize: '0.85rem' }} // Dynamic text color
                        >
                          {reply.reply}
                        </Typography>
                        <Typography
                          component="span"
                          sx={{ color: darkMode ? '#B0B0B0' : '#8E8E93', fontSize: '0.75rem', marginLeft: '10px' }} // Lighter gray in dark mode
                        >
                            {new Date(reply.timestamp).toLocaleString()}
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                ))}
              </List>
              <AddReplyForm
                
                reviewData={{ instructor, reviewIndex }}
                courseId={courseId}
                onReplyAdded={(newReply) => {
                  // Add the new reply to the local state
                  setReplies((prevReplies) => [...prevReplies, newReply]);
                  setReplyCount(replyCount + 1);
                }}
              />
            </>
          )}
        </Box>
      </motion.div>
    );
  };
  

  const renderReviews = () => {
    const filteredReviews = selectedProfessor
      ? reviews.filter((item) => item.instructor === selectedProfessor)
      : reviews;
  
    const indexOfLastReview = currentPage * reviewsPerPage;
    const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
    const currentReviews = filteredReviews.slice(indexOfFirstReview, indexOfLastReview);
  
    let lastInstructor = '';
  
    return (
      <List sx={{ maxWidth: '100%', margin: '0' }}>
        {currentReviews.map((item, idx) => {
          if (!item || typeof item !== 'object') {
            console.warn('Invalid review item:', item);
            return null;
          }
  
          const showInstructor = item.instructor !== lastInstructor;
          lastInstructor = item.instructor;
  
          const { prefix, rest } = splitReviewText(item.review);
          const replies = Array.isArray(item.replies) ? item.replies : [];
  
          return (
            <React.Fragment key={idx}>
              {showInstructor && (
                <Typography
                  variant="h6"
                  sx={{
                    marginTop: '20px',
                    color: textColor, // Use dynamic text color
                    textAlign: 'left',
                    fontWeight: 600,
                  }}
                >
                  {item.instructor}
                </Typography>
              )}
              <ReviewItem
                key={idx}
                instructor={item.instructor}
                prefix={prefix}
                rest={rest}
                replies={replies}
                courseId={courseId}
                reviewIndex={item.reviewIndex}
                onReplyAdded={fetchReviews}
              />
            </React.Fragment>
          );
        })}
      </List>
    );
  };
  const [courseMetrics, setCourseMetrics] = useState(null);

useEffect(() => {
  const fetchMetrics = async () => {
    const courseDoc = await getDoc(doc(db, 'courses', courseId));
    if (courseDoc.exists()) {
      setCourseMetrics(courseDoc.data().metrics);
    }
  };
  fetchMetrics();
}, [courseId]);


  const totalPages = Math.ceil(reviews.length / reviewsPerPage);

  const renderPageButtons = () => {
    let pages = [];
    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(
          <Button
            key={i}
            onClick={() => handleChangePage(i)}
            disabled={currentPage === i}
            sx={{
              color: '#fff',
              backgroundColor: currentPage === i ? '#571CE0' : '#A074E8',
              '&:hover': {
                backgroundColor: currentPage === i ? '#7E55CC' : '#7E55CC',
              },
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              margin: '0 2px',
              borderRadius: '50%',
              width: '36px',
              height: '36px',
            }}
          >
            {i}
          </Button>
        );
      }
    } else {
      if (currentPage <= 3) {
        for (let i = 1; i <= 4; i++) {
          pages.push(
            <Button
              key={i}
              onClick={() => handleChangePage(i)}
              disabled={currentPage === i}
              sx={{
                color: '#fff',
                backgroundColor: currentPage === i ? '#571CE0' : '#A074E8',
                '&:hover': {
                  backgroundColor: currentPage === i ? '#7E55CC' : '#7E55CC',
                },
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                margin: '0 2px',
                borderRadius: '50%',
                width: '36px',
                height: '36px',
              }}
            >
              {i}
            </Button>
          );
        }
        pages.push(
          <Button key="ellipsis" disabled sx={{ color: '#fff', margin: '0 2px' }}>
            ...
          </Button>
        );
        pages.push(
          <Button
            key={totalPages}
            onClick={() => handleChangePage(totalPages)}
            disabled={currentPage === totalPages}
            sx={{
              color: '#fff',
              backgroundColor: currentPage === totalPages ? '#571CE0' : '#A074E8',
              '&:hover': {
                backgroundColor: currentPage === totalPages ? '#7E55CC' : '#7E55CC',
              },
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              margin: '0 2px',
              borderRadius: '50%',
              width: '36px',
              height: '36px',
            }}
          >
            {totalPages}
          </Button>
        );
      } else if (currentPage > totalPages - 3) {
        pages.push(
          <Button
            key={1}
            onClick={() => handleChangePage(1)}
            disabled={currentPage === 1}
            sx={{
              color: '#fff',
              backgroundColor: currentPage === 1 ? '#571CE0' : '#A074E8',
              '&:hover': {
                backgroundColor: currentPage === 1 ? '#7E55CC' : '#7E55CC',
              },
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              margin: '0 2px',
              borderRadius: '50%',
              width: '36px',
              height: '36px',
            }}
          >
            1
          </Button>
        );
        pages.push(
          <Button key="ellipsis" disabled sx={{ color: '#fff', margin: '0 2px' }}>
            ...
          </Button>
        );
        for (let i = totalPages - 3; i <= totalPages; i++) {
          pages.push(
            <Button
              key={i}
              onClick={() => handleChangePage(i)}
              disabled={currentPage === i}
              sx={{
                color: '#fff',
                backgroundColor: currentPage === i ? '#571CE0' : '#A074E8',
                '&:hover': {
                  backgroundColor: currentPage === i ? '#7E55CC' : '#7E55CC',
                },
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                margin: '0 2px',
                borderRadius: '50%',
                width: '36px',
                height: '36px',
              }}
            >
              {i}
            </Button>
          );
        }
      } else {
        pages.push(
          <Button
            key={1}
            onClick={() => handleChangePage(1)}
            disabled={currentPage === 1}
            sx={{
              color: '#fff',
              backgroundColor: currentPage === 1 ? '#571CE0' : '#A074E8',
              '&:hover': {
                backgroundColor: currentPage === 1 ? '#7E55CC' : '#7E55CC',
              },
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              margin: '0 2px',
              borderRadius: '50%',
              width: '36px',
              height: '36px',
            }}
          >
            1
          </Button>
        );
        pages.push(
          <Button key="ellipsis1" disabled sx={{ color: '#fff', margin: '0 2px' }}>
            ...
          </Button>
        );
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pages.push(
            <Button
              key={i}
              onClick={() => handleChangePage(i)}
              disabled={currentPage === i}
              sx={{
                color: '#fff',
                backgroundColor: currentPage === i ? '#571CE0' : '#A074E8',
                '&:hover': {
                  backgroundColor: currentPage === i ? '#7E55CC' : '#7E55CC',
                },
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                margin: '0 2px',
                borderRadius: '50%',
                width: '36px',
                height: '36px',
              }}
            >
              {i}
            </Button>
          );
        }
        pages.push(
          <Button key="ellipsis2" disabled sx={{ color: '#fff', margin: '0 2px' }}>
            ...
          </Button>
        );
        pages.push(
          <Button
            key={totalPages}
            onClick={() => handleChangePage(totalPages)}
            disabled={currentPage === totalPages}
            sx={{
              color: '#fff',
              backgroundColor: currentPage === totalPages ? '#571CE0' : '#A074E8',
              '&:hover': {
                backgroundColor: currentPage === totalPages ? '#7E55CC' : '#7E55CC',
              },
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              margin: '0 2px',
              borderRadius: '50%',
              width: '36px',
              height: '36px',
            }}
          >
            {totalPages}
          </Button>
        );
      }
    }
    return pages;
  };

  const Legend = () => (
    <Box sx={{ marginTop: 2, marginBottom: 2 }}>
      <Typography variant="caption" sx={{ display: 'block' }}>
        <span style={{ backgroundColor: '#e6f7ff', padding: '2px 4px' }}>Highlighted professors</span> are teaching the current term.
      </Typography>
    </Box>
  );

  const courseName = courseId.split('_')[1];

  const uniqueProfessors = [...new Set(reviews.map((item) => item.instructor))];

  return (
    <Box
  sx={{
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    background: mainBgColor, // Use dynamic background color
    color: textColor,         // Use dynamic text color
    textAlign: 'left',
    fontFamily: 'SF Pro Display, sans-serif',
    padding: '40px',
  }}
>
      <Container maxWidth="lg">
      <Card
  sx={{
    marginBottom: 4,
    padding: 4,
    backgroundColor: paperBgColor, // Use dynamic paper background color
    color: textColor,               // Use dynamic text color
    boxShadow: 'none',
    borderRadius: '16px',
    border: darkMode ? '1px solid #444444' : '1px solid #D1D1D6', // Dynamic border color
    width: '100%',
    maxWidth: '100%',
  }}
>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: 3,
        justifyContent: 'space-between',

      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography
        variant="h4"
        gutterBottom
        textAlign="left"
        sx={{
          fontWeight: 600,
          fontSize: '2rem',
          marginBottom: 0,
          color: headerTextColor, // Use dynamic header text color
        }}
>
          {courseName}
        </Typography>
        {isTaughtCurrentTerm && (
          <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '2rem',
            marginLeft: 2,
          }}
        >
          <Box
            sx={{
              backgroundColor: darkMode ? '#333333' : '#E5F0FF', // Dark mode background or light blue
              padding: '2px 8px',
              borderRadius: '12px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="body2"
              sx={{
                fontSize: '0.9rem',
                color: darkMode ? '#FFFFFF' : '#1D1D1F', // Dynamic text color
              }}
            >
              25W
            </Typography>
          </Box>
        </Box>
        
        )}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <StyledTabs value={tabValue} onChange={handleTabChange}>
          <StyledTab icon={<AutoAwesomeIcon />} label="Description" />
          <StyledTab icon={<EqualizerIcon />} label="Medians" />
          <StyledTab icon={<QueryStatsIcon />} label="Course Metrics" />
          {isBetaUser && (
            <StyledTab icon={<ScienceIcon />} label="Beta" />
          )}
        </StyledTabs>
        <Tooltip
          title={pinned ? 'Unpin Course' : 'Pin course on your Profile'}
        >
          <IconButton
  onClick={handlePinCourse}
  sx={{
    color: pinned ? '#007AFF' : (darkMode ? '#FFFFFF' : '#8E8E93'), // Blue if pinned, white or gray otherwise
    backgroundColor: darkMode ? (pinned ? '#007AFF' : '#1C1F43') : 'transparent', // Optional: Add background for better visibility
    '&:hover': {
      backgroundColor: darkMode ? (pinned ? '#0066D6' : '#24273c') : '#E0E0E0', // Darker shade on hover
    },
    marginLeft: 1,
    padding: '6px', // Consistent padding
  }}
>
  {pinned ? (
    <PushPin sx={{ fontSize: 24 }} />
  ) : (
    <PushPinOutlined sx={{ fontSize: 24 }} />
  )}
</IconButton>

        </Tooltip>
      </Box>
    </Box>

    {renderTabContent()}
  </Card>




        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
             <CircularProgress sx={{ color: darkMode ? '#571CE0' : '#571CE0' }} /> {/* Assuming the color remains the same */}
          </Box>

        ) : error ? (
          <Alert 
  severity="error" 
  sx={{ 
    textAlign: 'left',
    backgroundColor: darkMode ? '#333333' : '#fdecea', // Dark mode background or light red for error
    color: darkMode ? '#FFFFFF' : '#611a15',           // Dark mode text color or dark red
  }}
>
  {error}
</Alert>

        ) : reviews.length > 0 ? (
          <>
          {/* {course && (
            <Box
              sx={{
                position: 'fixed',
                top: '150px',
                left: '20px',
                display: { xs: 'none', sm: 'flex' },
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column', // Stack the content vertically
                  alignItems: 'center',
                  borderRadius: '20px', // Rounded corners for a smoother look
                  backgroundColor: '#FFF', // White background for contrast
                  border: '2px solid #571CE0',
                  boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
                  padding: '20px', // Padding to give space around content
                  justifyContent: 'center',
                  width: '130px', // Adjust width for vertical layout
                  boxSizing: 'border-box',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginBottom: '20px', // Space between layup and quality sections
                  }}
                >
                  <Tooltip title="Upvote Layup">
                    <IconButton
                      onClick={() => handleVote('upvote')}
                      sx={{ color: vote === 'upvote' ? '#571CE0' : 'grey', padding: 0 }}
                    >
                      <ArrowUpward sx={{ fontSize: 24 }} />
                    </IconButton>
                  </Tooltip>
                  <Typography variant="h6" sx={{ color: '#571CE0', fontSize: '1.5rem', fontWeight: 700 }}>
                    {course.layup || 0}
                  </Typography>
                  <Tooltip title="Downvote Layup">
                    <IconButton
                      onClick={() => handleVote('downvote')}
                      sx={{ color: vote === 'downvote' ? '#571CE0' : 'grey', padding: 0 }}
                    >
                      <ArrowDownward sx={{ fontSize: 24 }} />
                    </IconButton>
                  </Tooltip>
                  <Typography variant="caption" sx={{ color: '#571CE0', marginTop: '10px', textAlign: 'center', fontWeight: 500 }}>
                    Is it a layup?
                  </Typography>
                </Box>
        
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Tooltip title="Upvote Quality">
                    <IconButton
                      onClick={() => handleQualityVote('upvote')}
                      sx={{ color: vote === 'upvote' ? '#571CE0' : 'grey', padding: 0 }}
                    >
                      <ArrowUpward sx={{ fontSize: 24 }} />
                    </IconButton>
                  </Tooltip>
                  <Typography variant="h6" sx={{ color: '#571CE0', fontSize: '1.5rem', fontWeight: 700 }}>
                    {quality || 0}
                  </Typography>
                  <Tooltip title="Downvote Quality">
                    <IconButton
                      onClick={() => handleQualityVote('downvote')}
                      sx={{ color: vote === 'downvote' ? '#571CE0' : 'grey', padding: 0 }}
                    >
                      <ArrowDownward sx={{ fontSize: 24 }} />
                    </IconButton>
                  </Tooltip>
                  <Typography variant="caption" sx={{ color: '#571CE0', marginTop: '10px', textAlign: 'center', fontWeight: 500 }}>
                    Quality of the course?
                  </Typography>
                </Box>
              </Box>
            </Box>
          )} */}
        
        <Typography
  variant="h5"
  gutterBottom
  sx={{ fontWeight: 600, color: headerTextColor, marginTop: 4 }}
>
  Professors
</Typography>

<TableContainer
  component={Paper}
  sx={{
    backgroundColor: paperBgColor,              // Dynamic background color
    marginTop: '20px',
    borderRadius: '12px',
    boxShadow: 'none',
    border: darkMode ? '1px solid #444444' : '1px solid #D1D1D6', // Dynamic border color
  }}
>
  <Table>
    <TableHead>
      <TableRow>
              <TableCell
          sx={{
            color: textColor,              // Use dynamic text color
            textAlign: 'left',
            fontWeight: 600,
            fontSize: '1rem',
            padding: '12px 16px',
            backgroundColor: tableHeaderBgColor, // Optional: Add background for headers
          }}
        >
          Name
        </TableCell>
        <TableCell
          sx={{
            color: textColor,              // Use dynamic text color
            textAlign: 'left',
            fontWeight: 600,
            fontSize: '1rem',
            padding: '12px 16px',
            backgroundColor: tableHeaderBgColor, // Optional: Add background for headers
          }}
        >
          Reviews
        </TableCell>

      </TableRow>
    </TableHead>
    <TableBody>
      {allProfessors
        .sort((a, b) => {
          const aIsCurrent = currentInstructors.includes(a);
          const bIsCurrent = currentInstructors.includes(b);
          if (aIsCurrent && !bIsCurrent) return -1;
          if (!aIsCurrent && bIsCurrent) return 1;
          return 0;
        })
        .slice(0, showAllProfessors ? undefined : 12)
        .map((professor, index) => {
          const isCurrent = currentInstructors.includes(professor);
          const reviewCount = reviews.filter(
            (review) => review.instructor === professor
          ).length;
          return (
            <TableRow
              key={index}
              component={Link}
              to={`/departments/${department}/courses/${courseId}/professors/${professor}`}
              sx={{
                backgroundColor: isCurrent
                  ? (darkMode ? '#1C1F43' : '#E5F0FF') // Dark mode or light blue
                  : (index % 2 === 0 ? tableRowEvenBgColor : tableRowOddBgColor),
                '&:hover': { backgroundColor: darkMode ? '#2a2a2a' : '#E5E5EA' }, // Darker on hover for dark mode
                cursor: 'pointer',
                textDecoration: 'none',
                color: 'inherit',
              }}
            >
              <TableCell
                sx={{
                  color: textColor,              // Use dynamic text color
                  padding: '12px 16px',
                  textAlign: 'left',
                  fontWeight: isCurrent ? 600 : 400,
                }}
              >
                {professor}
              </TableCell>
              <TableCell
                sx={{
                  color: textColor,              // Use dynamic text color
                  padding: '12px 16px',
                  textAlign: 'left',
                  fontWeight: 400,
                }}
              >
                {reviewCount}
              </TableCell>
            </TableRow>
          );
        })}
      {allProfessors.length > 12 && (
        <TableRow>
          <TableCell colSpan={2} sx={{ textAlign: 'center', padding: '16px' }}>
                  <Button
          onClick={() => setShowAllProfessors((prev) => !prev)}
          sx={{
            color: darkMode ? '#FFFFFF' : '#007AFF',                // Dynamic text color
            backgroundColor: darkMode ? '#007AFF' : 'transparent',   // Optional: Add background for better visibility
            fontWeight: 500,
            textTransform: 'none',
            padding: '8px 16px',
            '&:hover': {
              backgroundColor: darkMode ? '#0066D6' : '#E5E5EA',     // Darker shade on hover for dark mode
            },
          }}
        >
          {showAllProfessors ? 'Show Less' : 'More Professors'}
        </Button>

          </TableCell>
        </TableRow>
      )}
    </TableBody>
  </Table>
</TableContainer>
{/* Legend component */}
  <Typography variant="caption" sx={{ color: textColor, marginTop: 2 }}>
    <span
      style={{
        backgroundColor: darkMode ? '#1C1F43' : '#E5F0FF', // Use darkMode instead of isCurrent
        padding: '2px 4px',
        borderRadius: '4px',
        color: darkMode ? '#FFFFFF' : '#000000',
      }}
    >
      Highlighted professors
    </span>{' '}
    are teaching the current term.
  </Typography>


<Box
  sx={{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '-10px',
    marginTop: '60px',
  }}
>
<Typography
  variant="h5"
  gutterBottom
  sx={{ fontWeight: 600, color: headerTextColor }}
>
  Reviews
</Typography>

<FormControl
  size="small"
  sx={{
    minWidth: 150,
    backgroundColor: searchBgColor,                  // Dynamic background color
    borderRadius: '8px',
    border: darkMode ? '1px solid #444444' : '1px solid #D1D1D6', // Dynamic border color
  }}
>
  <InputLabel
    id="select-professor-label"
    sx={{ fontWeight: 400, color: darkMode ? '#FFFFFF' : '#00693E' }} // Dynamic text color
  >
    Professor
  </InputLabel>
  <Select
    labelId="select-professor-label"
    value={selectedProfessor}
    onChange={handleProfessorFilterChange}
    label="Professor"
    sx={{
      fontWeight: 400,
      color: textColor, // Ensure selected text color is dynamic
      backgroundColor: searchBgColor, // Match background
      '& .MuiSelect-select': {
        padding: '8px 12px',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: darkMode ? '#444444' : '#D1D1D6',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: darkMode ? '#555555' : '#A1A1A6',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#571CE0', // Primary color on focus
      },
    }}
  >
    <MenuItem value="">
      <em>All</em>
    </MenuItem>
    {uniqueProfessors.map((professor, index) => (
      <MenuItem key={index} value={professor} sx={{ fontWeight: 400 }}>
        {professor}
      </MenuItem>
    ))}
  </Select>
</FormControl>

</Box>

            {renderReviews()}

            <Box
  sx={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
    width: '100%',
    gap: '16px', // Increase space between elements for a more airy feel
  }}
>
  <Tooltip title="Previous Page" placement="top">
    <span>
      <IconButton
        onClick={() => handleChangePage(currentPage - 1)}
        disabled={currentPage === 1}
        sx={{
          color: darkMode ? '#FFFFFF' : '#000000', // Dynamic text color
          backgroundColor: darkMode 
            ? (currentPage === 1 ? '#1C1F43' : '#1C1F43') 
            : (currentPage === 1 ? '#F5F5F7' : '#F5F5F7'), // Dark mode backgrounds
          borderRadius: '12px',
          padding: '12px',
          border: darkMode ? '1px solid #444444' : '1px solid #D1D1D6', // Dynamic border
          '&:hover': {
            backgroundColor: darkMode 
              ? (currentPage === 1 ? '#24273c' : '#24273c') 
              : (currentPage === 1 ? '#F5F5F7' : '#E0E0E0'), // Darker shade on hover
          },
          transition: 'background-color 0.3s ease',
        }}
      >
        <ArrowBack sx={{ fontSize: '20px' }} />
      </IconButton>
    </span>
  </Tooltip>

  <ButtonGroup
  variant="text"
  sx={{
    '& .MuiButtonGroup-grouped': {
      minWidth: '40px',
      height: '40px',
      borderRadius: '12px',
      backgroundColor: darkMode ? '#1C1F43' : '#F5F5F7', // Dynamic background color
      color: darkMode ? '#FFFFFF' : '#000000',          // Dynamic text color
      border: darkMode ? '1px solid #444444' : '1px solid #D1D1D6', // Dynamic border
      margin: '0 2px',
      fontSize: '16px',
      '&:hover': {
        backgroundColor: darkMode ? '#24273c' : '#E0E0E0', // Darker shade on hover
      },
      '&.Mui-selected': {
        backgroundColor: '#007AFF', // Assuming selected color remains the same
        color: '#fff',
        '&:hover': {
          backgroundColor: '#0066CC',
        },
      },
    },
  }}
>
  {renderPageButtons()}
</ButtonGroup>


  <Tooltip title="Next Page" placement="top">
    <span>
            <IconButton
          onClick={() => handleChangePage(currentPage + 1)}
          disabled={currentPage === totalPages}
          sx={{
            color: darkMode ? '#FFFFFF' : '#000000', // Dynamic text color
            backgroundColor: darkMode 
              ? (currentPage === totalPages ? '#1C1F43' : '#1C1F43') 
              : (currentPage === totalPages ? '#F5F5F7' : '#F5F5F7'), // Dark mode backgrounds
            borderRadius: '12px',
            padding: '12px',
            border: darkMode ? '1px solid #444444' : '1px solid #D1D1D6', // Dynamic border
            '&:hover': {
              backgroundColor: darkMode 
                ? (currentPage === totalPages ? '#24273c' : '#24273c') 
                : (currentPage === totalPages ? '#F5F5F7' : '#E0E0E0'), // Darker shade on hover
            },
            transition: 'background-color 0.3s ease',
          }}
        >
          <ArrowForward sx={{ fontSize: '20px' }} />
        </IconButton>

    </span>
  </Tooltip>
</Box>



          </>
        ) : (
          <>
            <Box sx={{ textAlign: 'center', marginBottom: '20px' }}>
              <Typography variant="h6" sx={{ marginBottom: '20px', color: '#571CE0', fontWeight: 600 }}>
                No reviews available
              </Typography>
              <Typography variant="h6" sx={{ color: '#571CE0', fontWeight: 600 }}>
                Don't be shy, be the first one to add a review!
              </Typography>
              <Box
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}
              >
              </Box>
            </Box>
          </>
        )}
        <Box
          sx={{
            background: '',
            padding: '20px',
            borderRadius: '12px',
            width: '100%',
            maxWidth: '100%',
            color: '#fff',
            boxShadow: 'none', // Set this to 'none' or remove it entirely
          }}
        >
          <Container maxWidth="md">
          <AddReviewForm onReviewAdded={fetchReviews} darkMode={darkMode} />
          </Container>
        </Box>
      </Container>
    </Box>
  );
};

export default CourseReviewsPage;